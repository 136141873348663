import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom'

const HeaderContainer = styled.div`
    padding: 20px;
    font-size: 30px;
    color: #FFFFFF;

`;

const HeaderLinks = styled.div`
    font-size: 18px;
    padding-top: 10px;
    cursor: pointer;
    display: flex;
`

const HeaderLink = styled.div`
    padding-left: 20px;
    &:hover {
        color: #F0CFB9;
    }
`;

const HeaderActualLink = styled.a`
    text-decoration: none;
    color: #FFFFFF;
    &:hover {
        color: #F0CFB9;
    }
`;

const DiscordLink = styled.a`
    position: absolute;
    right: 10px;
    top: 10px;
`;
class Header extends React.Component {
    render(){
        return  <HeaderContainer>
                    FFXIV Roleplay Toolkit
                    <HeaderLinks>
                        <Link style={{color: "white", textDecoration: "none"}} to="PostMaker" activeClassName="current"><HeaderLink>Post Maker</HeaderLink></Link>
                        <HeaderLink><HeaderActualLink href="http://ffxiv-crystal-wiki.com/">Wiki</HeaderActualLink></HeaderLink>
                        <HeaderLink><HeaderActualLink href="https://ko-fi.com/tandeeee">Gimme a ko-fi</HeaderActualLink></HeaderLink>
                    </HeaderLinks>
                    <DiscordLink className="discord-widget" href="https://discord.gg/mNCFpcX" title="Join us on Discord">
                        <img alt="discord link" src="https://discordapp.com/api/guilds/659128312878202891/embed.png?style=banner2" />
                    </DiscordLink>
                </HeaderContainer>
    }
}

export default Header;
