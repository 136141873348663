import React from "react";
import styled from "styled-components";
import Select from 'react-select';
import ReactCopy from "react-copy-to-clipboard";

const options = [
    { label: "say", value: "/s" },
    { label: "emote", value: "/em" },
    { label: "party", value: "/p" },
    { label: "tell", value: "/t" }
];

const ContainerDiv = styled.div`
  display: flex;
  height: 100%;
`;

const InputOutputDiv = styled.div`
  width: 45%;
  padding: 0 10px;
  height: 100%;
  overflow: auto;
`

const OutputDiv = styled.div`
  padding: 5px;
  cursor: pointer;
  .clicked {
    opacity: 60%
  }
`

class Post extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            clicked: false
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({clicked: false})
    }

    render(){
        return (
            <ReactCopy onCopy={() => this.setState({clicked: true})} text={this.props.outputText} key={this.props.currentIndex}>
                    <OutputDiv>
                        <div class={this.state.clicked && "clicked"}>
                            {this.state.clicked && <div>Copied!</div>}
                            { this.props.outputText }
                        </div>
                    </OutputDiv>
            </ReactCopy>)
    }
}

class PostBuilder extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            input: '',
            selectedOption: { label: "say", value: "/s" },
            tell: ''
        }
    }

    handleSelect = selectedOption => {
        this.setState(
            { selectedOption }
        );
    };

    renderOutput(){
        let beginningText = this.state.selectedOption.value;
        if( this.state.selectedOption.value === "/t")
            beginningText = beginningText + " " + this.state.tell;

        let myWords = this.state.input.split(" ");
        let output = [];
        let currentIndex = 0;
        do{
            let outputText = beginningText;
            while( (outputText + myWords[currentIndex]).length < 500 ){
                if( myWords.length < currentIndex + 1 )
                    break;
                outputText = outputText + " " + myWords[currentIndex];
                currentIndex = currentIndex + 1;
            }
            output.push(<Post outputText={outputText} currentIndex={currentIndex} />);
        }while( myWords.length - 1 > currentIndex );
        return <div>{output}</div>
    }

    render(){
        return  (
            <ContainerDiv>
                <InputOutputDiv>
                    <div style={{ fontSize: "20px", paddingBottom: "10px"}}>Post Maker</div>
                    <div style={{ width: "50%", paddingBottom: "5px"}}>
                        Chat Type
                        <Select options={options} value={ this.state.selectedOption } defaultValue={"/say"} onChange={this.handleSelect }/>
                        { this.state.selectedOption && this.state.selectedOption.label === "tell" &&
                            <div>Tell Recipient <input value={this.state.tell}  onChange={(e) => this.setState({tell: e.target.value })} style={{marginLeft: "5px"}}/></div> }
                    </div>
                    <textarea value={this.state.input} onChange={(e) => this.setState({input: e.target.value })} style={{ width: "100%", height: "300px", marginTop: "5px"}} />
                    <button onClick={() => this.setState({input: ""})}>Clear</button>
                </InputOutputDiv>
                <InputOutputDiv>
                    Word Count { this.state.input.length ? this.state.input.split(" ").length : "0"}
                    {this.renderOutput()}
                </InputOutputDiv>
            </ContainerDiv>
        )
    }
}

export default PostBuilder;
