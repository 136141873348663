import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import PostBuilder from './routes/postbuilder/PostBuilder';
import Header from './components/header/header';
import * as serviceWorker from './serviceWorker';
import styled from "styled-components";

const ContainerDiv = styled.div`
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 5px;
  height: calc(100vh - 200px);
  margin: 0px 40px
`

const routing = (
    <Router>
        <div style={ { backgroundColor: "#535E99", height: "100vh", width: "100vw" } }>
        <Header />
        <ContainerDiv>
            <Route path="/" component={PostBuilder} />
            <Route path="/PostBuilder" component={PostBuilder} />
        </ContainerDiv>
            <div style={{paddingLeft: "50px", fontSize: "10px"}}>Made by Tande @ElysiumTan</div>
        </div>
    </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
